<template>
  <div class="home">
    <section id="banner">
      <div>
        <img src="@/assets/logo.png" alt="Logo do clube" />
        <p>
          Fundado em 2019, o clube desenvolve atividades que promovem o
          desenvolvimento físico, mental e espiritual de jovens desbravadores de
          10 e 15 anos, na Cidade de Santa Branca - SP
        </p>
        <router-link to="/socio" class="button"
          >Faça parte dessa história</router-link
        >
      </div>
      <div class="effect"></div>
    </section>
    <div id="container">
      <section id="historia">
        <div>
          <h2>Nossa História</h2>
          <p>
            Em 2018, 3 membros da IASD Santa Branca iniciaram os primeiros
            diálogos para abertura de um clube nesta cidade que se iniciaria em
            2019. Nos primeiros meses por falta de engajamento e dificuldade em
            formar a diretoria, o sonho foi adiado, porém em meados de 2019
            Ezequiel Santos Souza, aceitou que seu nome fosse sugerido para
            diretor do novo clube, e a partir daí, outras pessoas aceitaram
            também fazer parte desta diretoria e a chama foi reacesa. Em 26 de
            Outubro de 2019 foi votada a primeira diretoria do Clube que em
            dezembro de 2019 recebeu o nome de Mensageiros da Colina. Inciando
            2020 com a sua primeira diretoria. Em 2022 conseguimos realizar o
            sonho de participar de nosso primeiro Campori na cidade de Americana
            - SP, e em 2023 na cidade de Barretos participamos do Campori Fé
            Invencível da UCB.
          </p>
          <a
            href="https://www.youtube.com/channel/UC_De7e3C6x2e2TGA9-qKsEw"
            class="button"
            target="_blank"
            >Acesse o canal</a
          >
        </div>
        <iframe
          src="https://www.youtube.com/embed/o_n5jeC-M8M?si=LzMKFjq-s0eB_g2A&amp;controls=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </section>
      <section id="maranata">
        <img
          src="@/assets/ezequiel.png"
          alt="Foto do diretor Ezequiel"
          loading="lazy"
        />
        <div>
          <h2>Maranata!</h2>
          <p>
            Nos reunimos semanalmente desenvolvendo talentos, habilidades,
            percepções e o gosto pela natureza, trabalhando em equipe nos
            preparando, também, para prestar socorro em calamidades Em tudo o
            que fazemos procuramos desenvolver amor a Deus e ao próximo, além
            de, fazer muitos amigos. Demonstramos habilidade com a disciplina
            através de ordem unida, e temos a criatividade despertada pelas
            artes manuais. Incentivamos, também, um estilo de vida saudável
            livre do uso de fumo, álcool e outras drogas.
          </p>
        </div>
      </section>
      <section id="atividades">
        <h2>Atividades</h2>
        <carousel-main :slides="slides" width="100%" height="30vw">
        </carousel-main>
      </section>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/HomePage.scss"></style>

<script>
import CarouselMain from "@/components/Carousel/CarouselMain";

export default {
  name: "HomePage",
  mounted() {
    this.matchHeight();
  },
  data() {
    return {
      slides: [
        "assets/carrossel/1.png",
        "assets/carrossel/2.png",
        "assets/carrossel/3.png",
        "assets/carrossel/4.png",
      ],
    };
  },
  methods: {
    matchHeight() {
      let navbarHeight = document.querySelector("#navbar").offsetHeight;
      document
        .querySelector("#banner")
        .style.setProperty("height", `calc(100vh - ${navbarHeight}px)`);
    },
  },
  components: {
    CarouselMain,
  },
};
</script>
