<template>
  <div
    class="carousel"
    :style="carouselSize"
    @keydown="checkSlide($event)"
    tabindex="0"
  >
    <carousel-slide
      v-for="(slide, index) in slides"
      :key="index"
      :index="index"
      :visibleSlide="slideIndex"
    >
      <img :src="require(`@/${slide}`)" :alt="slide" loading="lazy" />
    </carousel-slide>
    <button @click.prevent="next" class="carousel-btn btn-next">
      <img src="@/assets/seta.svg" alt="Proximo" />
    </button>
    <button @click.prevent="prev" class="carousel-btn btn-prev">
      <img src="@/assets/seta.svg" alt="Anterior" />
    </button>
  </div>
</template>
<script>
import CarouselSlide from "@/components/Carousel/CarouselSlide";

export default {
  props: ["slides", "width", "height"],
  components: { CarouselSlide },
  data() {
    return {
      slideIndex: 0,
      slideDirection: "",
    };
  },
  computed: {
    carouselSize() {
      let width = this.width || "800px";
      let height = this.height || "500px";
      return `width: ${width}; height: ${height};`;
    },
    slidesLength() {
      return this.slides.length;
    },
  },
  methods: {
    next() {
      this.slideIndex++;
      if (this.slideIndex >= this.slidesLength) {
        this.slideIndex = 0;
      }
      this.slideDirection = "slide-right";
      this.resetAutoSlide();
    },
    prev() {
      this.slideIndex--;
      if (this.slideIndex < 0) {
        this.slideIndex = this.slidesLength - 1;
      }
      this.slideDirection = "slide-left";
      this.resetAutoSlide();
    },
    checkSlide(event) {
      if (event.keyCode === 39) {
        this.next();
      } else if (event.keyCode === 37) {
        this.prev();
      } else {
        return;
      }
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.next();
      }, 3000); // 3 sec
    },
    resetAutoSlide() {
      clearInterval(this.autoSlideInterval);
      this.startAutoSlide();
    },
  },
  mounted() {
    this.startAutoSlide();
  },
};
</script>
<style lang="scss" src="@/styles/Carousel.scss"></style>
