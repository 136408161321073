<template>
  <div id="socio">
    <div id="socio-container">
      <div>
        <h2>Seja um Sócio Desbravador!</h2>
        <div>
          <p>
            Esse projeto tem o objetivo de fortalecer o clube o fazendo
            prosperar para continuar esse trabalho para alcançar mais crianças.
            Para participar você escolhe um plano de assinatura mensal e, de
            acordo com o seu plano, terá vantagens! Você ajudará o clube, e
            ganhará brindes! Ele é semelhante com o sócio torcedor do futebol.
          </p>
        </div>
      </div>
      <img src="@/assets/cartao.jpeg" alt="Cartão de Sócio Desbravador" />
    </div>
    <div class="prices" id="planos">
      <div v-for="(plan, index) in plans" :key="index" class="prices-card">
        <h3 class="prices-card-header">{{ plan.name }}</h3>
        <p class="prices-card-pricing">
          <span>R$</span><span>{{ plan.price }}</span
          ><span>/{{ plan.type || "Mês" }}</span>
        </p>
        <ul class="prices-card-options">
          <li v-for="(option, optionIndex) in plan.options" :key="optionIndex">
            {{ option }}
          </li>
        </ul>
        <a :href="plan.src" target="_blank"
          ><button class="prices-card-button">Assine Agora</button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plans: [
        {
          name: "Bronze",
          price: 15,
          options: [
            "1 Carteirinha de Sócio Para Desconto de 10% nas ações do Clube (1 uso)",
            "1 Calendário exclusivo",
            "1 Caneca exclusiva",
          ],
          src: "https://sun.eduzz.com/1865029",
        },
        {
          name: "Diamante",
          price: 252,
          options: [
            "1 Carteirinha de Sócio Para Desconto de 10% nas ações do Clube (1 uso)",
            "1 Calendário exclusivo",
            "1 Caneca exclusiva",
            "1 Camiseta exclusiva",
          ],
          type: "Ano",
          src: "https://sun.eduzz.com/1865044",
        },
        {
          name: "Prata",
          price: 30,
          options: [
            "1 Carteirinha de Sócio para Desconto de 10% nas ações do Clube (1 uso)",
            "1 Calendário exclusivo",
            "1 Caneca exclusiva",
            "1 Camiseta exclusiva",
          ],
          src: "https://sun.eduzz.com/1865036",
        },
      ],
    };
  },
  mounted() {
    this.matchHeight();
  },
  methods: {
    matchHeight() {
      let navbarHeight = document.querySelector("#navbar").offsetHeight;
      document
        .querySelector("#socio #socio-container")
        .style.setProperty("height", `calc(100vh - ${navbarHeight}px)`);
    },
  },
};
</script>

<style lang="scss" scoped src="@/styles/SocioPage.scss"></style>
