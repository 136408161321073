<template>
  <div id="desbravafeira">
    <div>
      <h2>Desbravafeira</h2>
      <p>
        Caso se interessar por algum item, nos contate no
        <a href="https://www.instagram.com/mensageiros.colina/" target="_blank"
          >Instagram</a
        >
      </p>
    </div>
    <section id="peças">
      <h3>Por até 3 Reais</h3>
      <div class="fotos">
        <img
          v-for="foto in peças"
          :src="require(`@/assets/peças/${foto}`)"
          :alt="foto"
          :key="foto"
        />
      </div>
    </section>
    <section id="sebo">
      <h3>Sebo</h3>
      <div class="fotos">
        <img
          v-for="foto in sebo"
          :src="require(`@/assets/sebo/${foto}`)"
          :alt="foto"
          :key="foto"
        />
      </div>
    </section>
  </div>
</template>

<script>
const peças = require
  .context("@/assets/peças", false, /.jpeg$/)
  .keys()
  .map((key) => key.slice(2));
const sebo = require
  .context("@/assets/sebo", false, /.jpeg$/)
  .keys()
  .map((key) => key.slice(2));

export default {
  name: "DesbravaFeira",
  data() {
    return {
      peças: peças,
      sebo: sebo,
    };
  },
};
</script>

<style lang="scss" src="@/styles/Desbravafeira.scss"></style>
