<template>
  <div id="fanfarra">
    <h2>Nossa fanfarra</h2>
    <carousel-main :slides="slides" width="70vw" height="40vw"> </carousel-main>
  </div>
</template>

<style scoped lang="scss">
#fanfarra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 100vw;
  min-height: 80vh;
  padding: 2em 0.5em;

  @media screen and (max-width: 600px) {
    min-height: 40vh;
  }
}
</style>
<script>
import CarouselMain from "@/components/Carousel/CarouselMain";
export default {
  name: "FanfarraPage",
  data() {
    return {
      slides: [
        "assets/carrossel/fanfarra1.jpeg",
        "assets/carrossel/fanfarra2.jpeg",
      ],
    };
  },
  components: {
    CarouselMain,
  },
};
</script>
