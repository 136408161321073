import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import SocioPage from "../views/SocioPage.vue";
import FanfarraPage from "../views/FanfarraPage.vue";
import DesbravafeiraPage from "../views/DesbravafeiraPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/socio",
    name: "socio",
    component: SocioPage,
  },
  {
    path: "/fanfarra",
    name: "fanfarra",
    component: FanfarraPage,
  },
  {
    path: "/desbravafeira",
    name: "desbravafeira",
    component: DesbravafeiraPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
