<template>
  <NavBar />
  <router-view />
  <div id="footer">
    <div>
      <div>
        <h5>Mensageiros da Colina</h5>
        <p>
          O clube de desbravadores de Santa Branca. <br />
          <a
            href="https://www.instagram.com/mensageiros.colina/"
            target="_blank"
            ><img src="@/assets/ig.png" alt="Logo do instagram"
          /></a>
        </p>
      </div>
      <span
        >Site por
        <a href="https://m4thewz.github.io" target="_blank">Matheus Pacheco</a
        >.</span
      >
    </div>
    <div class="parceiros">
      <h6>Parceiros</h6>
      <div>
        <img
          src="@/assets/marmitinhas da mamae.jpeg"
          alt="Marmitnhas da Mamãe"
        />
        <img src="@/assets/celia.png" alt="Célia Manicure" />
      </div>
    </div>
  </div>
  <p id="direitos">Mensageiros da Colina©2019. Todos os direitos reservados</p>
</template>

<style lang="scss" src="@/styles/main.scss"></style>

<script>
import NavBar from "@/components/NavBar";
export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>
