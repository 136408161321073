<template>
  <nav id="navbar">
    <div class="mobile-nav">
      <h1><router-link to="/">Mensageiros da Colina</router-link></h1>

      <div class="buttons">
        <a
          href="https://clubes.adventistas.org/br/apv/39320/mensageiros-da-colina/"
          target="_blank"
          class="aviso"
        >
          Faça sua Rematrícula
        </a>
        <img
          @click="toggleNavbar"
          class="menu"
          src="@/assets/bars.svg"
          alt="Menu"
        />
      </div>
    </div>
    <ul>
      <li><router-link to="/#historia">Nossa História</router-link></li>
      <li><router-link to="/#atividades">Atividades</router-link></li>
      <li><router-link to="/socio">Sócio Desbravador</router-link></li>
      <li><router-link to="/socio#planos">Planos</router-link></li>
      <li><router-link to="/fanfarra">Fanfarra</router-link></li>
      <li><router-link to="/desbravafeira">Desbravafeira</router-link></li>
    </ul>
    <a
      href="https://clubes.adventistas.org/br/apv/39320/mensageiros-da-colina/"
      target="_blank"
      class="aviso"
    >
      Faça sua Rematrícula
    </a>
  </nav>
</template>

<style lang="scss">
#navbar {
  max-width: 100vw;
  padding: 1.3em 1.5em;
  background-color: #209ac7;
  z-index: 3;

  .buttons {
    display: none;
  }

  h1 {
    font-weight: 900;
    font-size: 1.67vw;
    a {
      text-decoration: none;
      color: white;
      transition: all ease 0.3s;

      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }
    @media screen and (max-width: 510px) {
      font-size: 1.3em !important;
    }
  }

  &,
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul {
    list-style-type: none;
    gap: 1.5em;
  }

  a:not(h1 a) {
    font-size: 0.92vw;
    font-weight: 700;
    color: white;
    text-decoration: none;
    transition: all ease 0.3s;

    &:not(.aviso):hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }
}
@media screen and (max-width: 900px) {
  #navbar {
    padding: 1em 1.2em;
    .mobile-nav {
      width: 100%;

      &,
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .menu {
        width: 1em;
        cursor: pointer;
        margin-left: 1em;
      }
    }
    &,
    ul {
      flex-direction: column;
    }

    h1 {
      font-size: 2em;
    }
    ul {
      padding-top: 10px;
      display: none;
      gap: 0.7em;
      a {
        font-size: 1.1em !important;
      }
    }
    > a {
      display: none;
    }
    &.open ul {
      display: flex;
    }
    .aviso {
      font-size: 0.6em;
    }
  }
}
@media screen and (max-width: 350px) {
  #navbar {
    .mobile-nav {
      flex-direction: column;
      gap: 0.6em;
      .buttons {
        width: 100%;
      }
    }
  }
}

.aviso {
  display: block;
  width: auto;
  border-radius: 7px;
  padding: 1em;

  color: white;
  background-color: #ff8500;
  text-align: center;
  font-size: 1vw;
  font-weight: bold;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: #b96305;
    color: white;
  }
}
</style>

<script>
export default {
  name: "NavBar",
  methods: {
    toggleNavbar() {
      document.querySelector("#navbar").classList.toggle("open");
    },
  },
};
</script>
