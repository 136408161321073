<template>
  <transition :name="dir">
    <div v-show="visible" class="carousel-slider">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["visibleSlide", "index"],
  computed: {
    visible() {
      return this.index === this.visibleSlide;
    },
    dir() {
      return this.$parent.slideDirection;
    },
  },
};
</script>
